import React, { useEffect } from "react";
import "../css/product.css";
import Contact from "./Contact";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";

function Gallery() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <section
        id="about"
        style={{
          height: "40vh",
          width: "100%",
          // zIndex: 2,
          backgroundImage: "url(/images/gallery-bck.PNG)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // position: "relative",
        }}
      >
        <div className="container">
          <div
            className="breadcrumbt"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              padding: "160px",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)",padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
              <h1 className="abtus-heading" style={{ color: "white" }}>
                Gallery
              </h1>
            </div>

            <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
              <Breadcrumb>
                <FontAwesomeIcon
                  icon={faHouse}
                  style={{
                    fontSize: "14px",
                    marginTop: "0px",
                    color: "white",
                    padding: "5px",
                  }}
                />
                <Breadcrumb.Item href="/" style={{ color: "white" }}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active style={{ color: "white" }}>
                  Gallery
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <Container>
        <div
          style={{
            textAlign: "center",
            padding: "50px 0",
            backgroundColor: "#f8f8f1",
          }}
        >
          <h2
            style={{
              fontFamily: "'Playfair Display', serif",
              fontSize: "3rem",
              color: "#742411",
              letterSpacing: "2px",
              textTransform: "uppercase",
              position: "relative",
              display: "inline-block",
              paddingBottom: "10px",
            }}
          >
            Explore Our Gallery{" "}
            <span
              style={{
                position: "absolute",
                left: "0",
                bottom: "-5px",
                width: "100%",
                height: "4px",
                backgroundColor: "#e1e276",
                borderRadius: "2px",
              }}
            ></span>
          </h2>
          <p
            style={{
              fontFamily: "'Nunito', sans-serif",
              fontSize: "1.2rem",
              color: "#444",
              maxWidth: "853px",  
              margin: "20px auto",
              lineHeight: "1.6",
              textShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            Welcome to M2BALU EXPORT! Take a closer look at our diverse
            collection of eco-friendly coir products, perfectly suited for
            agriculture, gardening, and everyday use. Each product is
            sustainably crafted from natural coconut fibers, bringing quality
            and sustainability to your home or farm.
          </p>
          <div
            className="row galleryRow gy-4"
          >
            <div
              className="row d-flex justify-content-center"
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              <video
                className="galleryVideo"
                controls
                data-aos="zoom-in"
                data-aos-delay="700"
                autoPlay 
                muted 
              >
                <source
                  src="../images/WhatsApp Video 2024-10-04 at 11.31.56 AM (1).mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/03-YanBrGWb5ws4JJLV.avif"
                alt=""
                className="galleryImg"
               data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/04-A85pogpekyurRNL5.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/05-AQEJNzy0W1Sz59Kz.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <img
                src="../images/gallery/06-Yyvoza3lxqcjLVN3.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/07-mxBrvZ6r2ySB99xg.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/10-AzGrq7qG6gi7LRXL.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/10-mxBrv89LyQS6Dvjj.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/13-Yg2l8qZVLBFa08bw.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <img
                src="../images/gallery/16-A3Qp0OrDNyfDJLvZ.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <img
                src="../images/gallery/19-mePLlb4Xe0FyNyxK.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <img
                src="../images/gallery/20-dOqbz8oE8buo2L8l.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/37-YyvozZ70yoSPXGVX.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12" >
              <img
                src="../images/gallery/gallery-1-mk39ExNDDyiK6pMZ.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <img
                src="../images/gallery/45-A1aK4BGQbJfPEB5Q.avif"
                alt=""
                className="galleryImg"
                data-aos="fade-up"
     data-aos-anchor-placement="top-center"
              />
            </div>

            <Contact />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Gallery;
