import React from 'react';
import '../css/contact.css';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div>
      <div className="contact">
        <div className="contact2">
          <h1>Contact us if you have any questions</h1>
          <p>We’d love to hear from you! Whether you have a question, feedback, or need support, feel free to reach out using the contact options below.</p>
          <p>Call Us At - +91-9092429900</p>
          <button className='morebtn'>
            <Link to='/contact-us' style={{ color: 'inherit', textDecoration: 'none' }}>
              Contact Us
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
