import React,{useEffect} from "react";
import "../css/product1.css";
import OwlCarousel from "react-owl-carousel";
import Contact from "./Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function Product5() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
  
  <section
            id="about"
            style={{
                 height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                   width:"100%"
                }}
              >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div>
                  <Breadcrumb style={{backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
<div className="mainp" style={{marginTop: "10px"}}>

<div className="product-main">
        <div className="lftside">
          <OwlCarousel loop margin={5} nav {...options} className="owlslide">
            <div class=" verticleCard">
              <img
                src="../images/coirhusk/husk-1-AR0JXJZG0ruDR9GD.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                 src="../images/coirhusk/husk-4-A3QpbpWp8lS9EQrP.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                   src="../images/coirhusk/husk-5-YNqJWJoDoMF2xplR.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
               src="../images/coirhusk/th-4-Yg2lMRJ8jzigl8e5.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
        
          </OwlCarousel>
        </div>
        <div className="rghtside" data-aos="fade-down">
            <h1>
            What are Coir Husk Chips and Why Use Them?
            </h1>
            <p>
            Coir husk chips are small pieces of coconut husk, processed into convenient sizes for use in gardening and horticulture. These chips offer a variety of benefits for plant growth and soil health. Here’s why you should consider using coir husk chips:</p>
        </div>

        </div>

 <div className="container" style={{color:"#fff"}}>
    <div className="row w-100 ">
<div className="col-md-9 border p-3 myCol"  data-aos="fade-down">
<h4>
Superior Moisture Retention and Aeration
</h4>
<ul className="text-start">
    <li>
    Optimal Hydration: Coir husk chips retain water effectively, ensuring plants receive consistent moisture levels. This is particularly beneficial for orchids and other epiphytic plants.    </li>
    <li>
    Improved Drainage: The fibrous structure of coir chips allows for excellent drainage and aeration, preventing waterlogging and promoting healthy root development. </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down" >
<h4>
Natural and Sustainable


</h4>
<ul className="text-start">
    <li>
    Renewable Resource: Coir husk chips are made from coconut husks, a byproduct of the coconut industry, making them a sustainable and environmentally friendly alternative to traditional mulches and soil conditioners.
    </li>
    <li>
    Biodegradable: Coir chips decompose naturally over time, enriching the soil with organic matter and reducing environmental impact.
    </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border myCol  p-3" data-aos="fade-down" >
<h4>
Versatile Applications
</h4>
<ul className="text-start">
    <li>
    Soil Amendment: When mixed into soil, coir husk chips improve soil structure, enhancing its water retention and aeration properties.
</li>
<li>
Mulching: Coir chips make an excellent mulch, helping to retain soil moisture, suppress weeds, and regulate soil temperature.
</li>

<li>
Hydroponics and Container Gardening: Ideal for use in hydroponic systems and container gardening, providing a stable and aerated growing medium for a variety of plants
</li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol"  data-aos="fade-down">
<h4>
Long-Lasting
</h4>
<ul>
  <li>
  Durability: Coir chips are durable and break down slowly, providing long-lasting benefits in the garden or growing setup.
  </li>

</ul>

</div>
    </div>

 </div>
</div>
<Contact/>

    </div>
  );
}

export default Product5;