import React,{useEffect} from 'react'
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Link} from 'react-router-dom'
import '../css/about.css'
import Card from 'react-bootstrap/Card';
import Contact from './Contact';
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { Container } from 'react-bootstrap';
import 'aos/dist/aos.css';

gsap.registerPlugin(ScrollTrigger);
function About() {
  useEffect(() => {
    AOS.init({
      duration: 1200,  
      delay: 300,     
    });
  }, []);
  return (
    <div >
    
    <section
            id="about"
            style={{
                 height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/abt-bck.PNG)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                  width:"100%"
                }}
              >
                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)",  padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                About Us
                  </h1>
                </div>

                <div style={{  backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                    About Us
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
       
          <div className="aboutus pt-2 pb-5">
<h1 className="abouth1">About Us</h1>
<div className="about mt-3">
    <div className="left">
    
<img src="../images/logo2.jpeg" alt="" srcset="" className="img1"/>

    </div>
    <div className="right">
<h4 className="few-words-about-us"> Few Words About Us</h4>
<p data-aos='fade-up' data-aos-duration='1500'>
At M2Balu Export, we harness the natural abundance of coconut coir to produce high-quality, eco-friendly products. With a commitment to sustainability and excellence, our journey began with a single manufacturing unit and has grown to encompass multiple facilities, providing versatile solutions for various agricultural and horticultural needs. Join us in promoting a greener future with innovative coir products
</p>

<button className='morebtn' data-aos='fade-left' data-aos-duration='1500'>
<Link to='/about'  onClick={() => window.location.href = '/about'}>Discover More</Link>
</button>

    </div>
</div>
      </div>



<Container>
      <div className="mission">
  <div className="missioncard" data-aos="fade-up">
    <div className="missionlft">
      <img
        src="../images/illustration-business-target-icon.png"
        alt=""
        className="mission-img"
      />
    </div>
    <div className="missionrght">
      <h4>Our Mission</h4>
      <p>
        Our mission is simple: to provide sustainable and eco-friendly coir
        products that enhance the quality of life while preserving the
        environment. We believe in harnessing the natural benefits of coir—a
        renewable resource that is both versatile and durable—to create products
        that serve our customers and the planet.
      </p>
    </div>
  </div>

  <div className="missioncard mt-3"  data-aos="fade-down">
    <div className="missionrght" >
      <h4>Our Commitment</h4>
      <p>
        At Ballu Coir Products, we are committed to making a positive impact on
        the environment and the communities we serve. We work closely with local
        coconut farmers and artisans, promoting fair trade practices and
        contributing to the local economy.
      </p>
    </div>
    <div className="missionlft">
      <img
        src="../images/handshake-concept-illustration.png"
        alt=""
        className="mission-img"
      />
    </div>
  </div>
  </div>
  </Container>
      <section class="our-story-section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 story-text"  data-aos="fade-up"
     data-aos-duration="3000">
        <h2>Balu Palaniswamy</h2>
        <h3>FOUNDER & CHAIRMAN OF M2B GROUPS</h3>
        <h4>Our Story</h4>
        <p data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
        In 2011, M2B Groups began with a single manufacturing unit under the name Tharun Coir Products, born from a simple yet powerful idea: to harness the natural abundance of coconut coir and transform it into high-quality, eco-friendly products. Our journey started in a small village where coconuts are not just a part of daily life but a symbol of nature's generosity.
        </p>
        
       
      </div>

      <div class="col-md-6 story-image" >
        <img src="../images/owner.jpg" alt="Balu Palaniswamy - Founder & Chairman"  data-aos="fade-up"/>
      </div>
    </div>
    
  </div>
  <div className="container">
    <div className="row">
    <h5>The Humble Beginnings</h5>
        <p ata-aos="fade-up"
     data-aos-duration="3000">
        In 2011, our founder, Balu Palaniswamy, recognized the untapped potential of coconut coir. With a deep-rooted passion for sustainability and a vision to make a positive impact on the environment, Balu Palaniswamy started M2B Groups. The early days were marked by hard work, experimentation, and a commitment to quality. Our first products were simple coir fiber and cocopeat block which was manufactured with traditional machine at the of beginning of  our company
        </p>
    <h5>Growth and Innovation</h5>
        <p data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
        As word spread about the durability and eco-friendliness of our products, demand grew. We expanded our range to include coir grow bags, rugs, and customized solutions, always staying true to our core values of sustainability and quality. Innovation became our driving force. We invested in research and development, exploring new applications for coir and improving our manufacturing processes. This commitment to innovation has allowed us to offer products that not only meet but exceed customer expectations. We started our business with a single manufacturing unit equipped with basic machinery. Today, we have experienced tremendous growth, expanding to multiple manufacturing units in Pollachi, now operating under M2B Groups. M2B Groups includes Tharun Coir Products, Ballu Coir Products, Harinee Bricks, and Ballu & Co.
        </p>
        <h5>Community and Sustainability</h5>
        <p  data-aos="fade-up"
     data-aos-duration="3000">
        From the beginning, our mission was about more than just business. We wanted to give back to the community that had supported us. We established fair trade practices, ensuring that our local farmers and artisans received fair wages and safe working conditions. We also initiated several community development projects, such as education programs and healthcare initiatives, to improve the quality of life for our workers and their families.
        </p>
        <h5>Reaching New Heights</h5>
        <p data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
        Over the years, M2B Groups has grown from a small local business to a recognized name in the coir industry. Our products are now used and loved by customers around the world. Despite this growth, we have never lost sight of our roots. We remain committed to sustainability, quality, and community. Each product we create is a testament to our journey and our unwavering dedication to making a positive impact.
        </p>
    </div>
    </div>
</section>



<Contact/>




    </div>
  )
}

export default About