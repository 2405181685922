import React,{useEffect} from "react";
import "../css/product1.css";
import OwlCarousel from "react-owl-carousel";
import Contact from "./Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function Product9() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
  
  <section
            id="about"
            style={{
                height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                   width:"100%"
                }}
              >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)",padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
<div className="mainp" style={{marginTop: "10px"}}>

<div className="product-main">
        <div className="lftside">
          <OwlCarousel loop margin={5} nav {...options} className="owlslide">
            <div class=" verticleCard">
              <img
                src="../images/coirrope/coir-rope-2-AMq4GwMD7Xu2RWR5.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                src="../images/coirrope/rope-4-A3QJN8o26JSL8xZq.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                 src="../images/coirrope/rope-5-dJo4VO85ylUPyB23.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
          </OwlCarousel>
        </div>
        <div className="rghtside" data-aos="fade-down">
            <h1>
            What is Coir Rope and Why Use It?
            </h1>
            <p>
            Coir rope is a versatile and durable rope made from coconut fibers, specifically derived from the outer husk of coconuts. It has been used for centuries for various applications due to its strength, durability, and eco-friendly properties. Here's why you should consider using coir rope:</p>
        </div>

        </div>

 <div className="container"  style={{color:"#fff"}}>
    <div className="row w-100 ">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down">
<h4>
Natural and Renewable
</h4>
<ul className="text-start">
    <li>
    Sustainable Material: Coir rope is made from natural coconut fibers, a renewable resource that is harvested from the outer husks of coconuts.</li>
    <li>
    Eco-Friendly: Its production has minimal environmental impact and supports sustainable agricultural practices.</li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down">
<h4>
Strength and Durability
</h4>
<ul className="text-start">
    <li>
    High Tensile Strength: Coir rope is known for its strong and durable nature, making it ideal for heavy-duty applications.  </li>
    <li>
    Resistance to Rot and Decay: It is naturally resistant to rot, decay, and moisture, ensuring long-lasting performance even in harsh conditions.</li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border myCol  p-3" data-aos="fade-down">
<h4>
Versatility in Applications
</h4>
<ul className="text-start">
    <li>
    Gardening and Landscaping: Coir rope is commonly used for trellising, supporting climbing plants, and creating decorative elements in gardens and landscapes.
</li>
<li>
Construction and Marine Use: It is also utilized in construction projects, as well as in marine applications such as boat rigging and fishing nets.
</li>
<li>
Crafts and Decor: Coir rope's natural texture and appearance make it a popular choice for crafting projects and decorative purposes.
</li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down">
<h4>
Biodegradable and Non-Toxic
</h4>
<ul>
  <li>
  Environmentally Friendly: Coir rope is biodegradable and breaks down naturally over time, without releasing harmful chemicals into the environment.</li>
<li>
Safe for Use: It is non-toxic and safe to handle, making it suitable for various applications in both indoor and outdoor settings.
</li>
</ul>

</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down">
<h4>
Easy to Work With
</h4>
<ul className="text-start">
    <li>
    Flexible and Malleable: Coir rope is easy to handle and manipulate, allowing for flexibility in usage and installation.
  </li>  
  <li>
  Can Be Cut to Size: It can be easily cut to the desired length without fraying, providing customization options for different projects.
    </li> 
</ul>
</div>
    </div>
 </div>
</div>
<Contact/>

    </div>
  );
}

export default Product9;
