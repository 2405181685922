import React from 'react'
import { FaWhatsapp } from 'react-icons/fa';
import '../css/WhatsappButton.css';

const WhatsAppButton = () => {
    const phoneNumber = "+919092429900"; // Replace with your phone number

    const openWhatsApp = () => {
      const message = "Hello, I'm interested in learning more about your product!";
      const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    };
  return (
    <div>
    <button className="whatsapp-button" onClick={openWhatsApp}>
      <FaWhatsapp size={35} /> 
    </button>
    </div>
  )
}

export default WhatsAppButton
