import React from "react";
import "../css/footer.css";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";

function Footer() {
  return (
    <div>
      <div className="footer mt-2">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h5
                style={{
                  color: "rgb(116, 36, 17)",
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "25px",
                }}
              >
                M2Balu Exports
              </h5>
              <p style={{ textAlign: "justify",fontSize:"16px" }}>
                We create sustainable, high-quality coir products for
                agricultural and horticultural needs, combining innovation with
                eco-friendly practices. Join us in promoting a greener future.
              </p>
            </div>

            {/* Quick Links Section */}
            <div className="col-md-3 Quick-Links">
              <h5   style={{
                  color: "rgb(116, 36, 17)",
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "25px",
                }}>Quick Links</h5>
              <ul style={{fontSize:"16px" }}>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/products">Products</a>
                </li>
                <li>
                  <a href="/gallery">Gallery</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>

            {/* Social Links Section */}
            <div className="col-md-3">
              <h5  style={{
                  color: "rgb(116, 36, 17)",
                  fontWeight: "700",
                  fontSize: "25px",
                }}>Social Media Links</h5>
              <a
                href="https://www.facebook.com/people/M2Balu-Export/61561456586077/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF className="social" />
              </a>
              <a
                href="https://x.com/i/flow/login?redirect_after_login=%2Fm2balu_export"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter className="social" />
              </a>
              <a
                href="https://www.instagram.com/m2balu_export/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram className="social" />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn className="social" />
              </a>
            </div>

            <div className="col-md-3">
              <h5  style={{
                  color: "rgb(116, 36, 17)",
                  fontWeight: "700",
                  fontSize: "25px",
                }}>Contact Us</h5>
                <div className="d-flex gap-3 justify-content-center">
                  <div className="align-items- center ">
                    <span className="phone-icon" style={{fontSize:"25px"}}><FaPhoneVolume/></span>
                  </div>
                  <div>
              <a href="tel:+919976999900">+91 9976999900</a> <br />
              <a href="tel:+919659599900">+91 9659599900</a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
