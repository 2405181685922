import React from 'react';
import '../css/WhyChoosenus.css';

const cardData = [
    {
        icon: "../images/sustainability.png",
        heading: "Sustainability",
        text: "Our products are made from natural coconut fibers, ensuring minimal environmental impact.",
    },
    {
        icon: "../images/quality-icon.png",
        heading: "Quality",
        text: "We adhere to the highest quality standards, ensuring our products are reliable and long-lasting.",
    },
    {
        icon: "../images/innovation.png",
        heading: "Innovation",
        text: " We continuously strive to innovate and improve our product offerings to meet the evolving needs of our customers.",
    },
    {
        icon: "../images/customer-centric.png",
        heading: "Customer-Centric",
        text: "Our customers are at the heart of everything we do. We are dedicated to providing exceptional service and support.",
    },
];

const WhyChoosenUsCard = () => {
  return (
    <>
    <div className="container-11">
    <h1 data-aos='flip-up' data-aos-duration='1500' className='reason'>Reason for Choosing Us</h1> <br />
    <p className='reason-para'>Experience the best service with us. Trust our expertise and dedication to excellence.</p>
    </div>
    <div className="card-container11">
      {cardData.map((card, index) => (
        <div className="card-12" key={index}>
          <img src={card.icon} alt={`${card.heading} icon`} className="card-icon-12" /> {/* Icon image */}
          <h3 className="card-heading-12">{card.heading}</h3>
          <p className="card-text-12">{card.text}</p>
        </div>
      ))}
    </div>
    </>
  );
};

export default WhyChoosenUsCard;
