import React,{useEffect} from "react";
import "../css/product1.css";
import OwlCarousel from "react-owl-carousel";
import Contact from "./Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import 'aos/dist/aos.css';

function Product1() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
  
  <section
            id="about"
            style={{
                height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                  width:"100%"
                }}
              >
                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
<div className="mainp" style={{marginTop: "10px"}}>

<div className="product-main">
        <div className="lftside">
          <OwlCarousel loop margin={5} nav {...options} className="owlslide">
            <div class="verticleCard"  >
              <img
                src="../images/coirpith/images_-4-1-mk3D1WGz3ZtEX00Q.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
            src="../images/coirpith/images_-5-1-AR0JOoJZZkIlrbRG.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
               src="../images/coirpith/pith-3-dOq4j21LX4FLWEB0.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                  src="../images/coirpith/pith-4-Yg29xrzNLMf8VXvW.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
           
          </OwlCarousel>
        </div>
        <div className="rghtside">
            <h1>
            What is Coir Yarn and Why Use It?
            </h1>
            <p>
            Coir yarn is a natural fiber product made from the husk of coconuts, spun into strong and durable threads. It is widely valued for its resilience, eco-friendliness, and versatility. Here’s why you should consider using coir yarn:
            </p>
    
        </div>

        </div>

        <div className="container" style={{ color: "#fff" }}>
    <div className="row w-100">
        <div className="col-md-9 border p-3 myCol" data-aos="fade-down">
            <h4>Durable and Strong</h4>
            <ul className="text-start">
                <li>High Tensile Strength: Coir yarn is known for its exceptional strength, making it suitable for heavy-duty applications such as ropes, mats, and upholstery.</li>
                <li>Longevity: Naturally resistant to rot, mold, and moisture, coir yarn products have a longer lifespan compared to other natural fibers.</li>
            </ul>
        </div>
    </div>

    <div className="row w-100 mt-3" data-aos="fade-down">
        <div className="col-md-9 border offset-3 p-3 myCol">
            <h4>Eco-Friendly and Sustainable</h4>
            <ul className="text-start">
                <li>Renewable Resource: Coir yarn is derived from coconut husks, a byproduct of the coconut industry, making it an eco-friendly and sustainable material.</li>
                <li>Biodegradable: Coir yarn decomposes naturally over time, reducing environmental impact and contributing to sustainability.</li>
            </ul>
        </div>
    </div>

    <div className="row w-100 mt-3" data-aos="fade-down">
        <div className="col-md-9 border myCol p-3">
            <h4>Versatile Applications</h4>
            <ul className="text-start">
                <li>Home and Garden: Used in products like mats, rugs, and upholstery, providing a natural and durable solution for home décor and gardening.</li>
                <li>Agriculture: Ideal for use in horticulture and agriculture as plant support, trellises, and erosion control solutions.</li>
                <li>Industrial Uses: Employed in the production of ropes, twines, and brushes due to its robust nature.</li>
            </ul>
        </div>
    </div>

    <div className="row w-100 mt-3" data-aos="fade-down">
        <div className="col-md-9 border offset-3 p-3 myCol">
            <h4>Natural Pest Resistance</h4>
            <ul className="text-start">
                <li>Pest Deterrent: Coir yarn is naturally resistant to pests and microbial attacks, reducing the need for chemical treatments and making it safer for various applications.</li>
            </ul>
        </div>
    </div>

    <div className="row w-100 mt-3" data-aos="fade-down">
        <div className="col-md-9 border p-3 myCol">
            <h4>Aesthetic Appeal</h4>
            <ul className="text-start">
                <li>Natural Look and Feel: Coir yarn has a rustic, natural appearance that adds a touch of elegance and earthiness to products, making it a popular choice for decorative items.</li>
            </ul>
        </div>
    </div>
</div>

</div>
<Contact/>

    </div>
  );
}

export default Product1;
