import React, { useState,useEffect } from "react";
import "../css/home.css";
import Carousel from "react-bootstrap/Carousel";
import Button from '@mui/material/Button';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Link} from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
import Testimonial from "./Testimonial";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Container } from "react-bootstrap";
import { Modal  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import WhyChoosenUsCard from "./WhyChoosenUsCard";

gsap.registerPlugin(ScrollTrigger);
function Home() {


  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useGSAP(() => {
    gsap.to(".carouselx", {
      x:-1800,
      scrollTrigger: {
        trigger: ".products",
        start: "top 15%",
        end: "bottom 0%",
        scrub:3,
        pin:true,
   
      },
    });
  });
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);



  return (
    <div>
      <div classNameName="carouselSection">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          className="carousel"
          fade
        >
          <Carousel.Item className="carouselItem">
            <img
              src="../images/cor-1.jpeg"
              alt=""
              srcset=""
              className="carouselImage"
            />
            <div className="text cor-design-34" >
      
              <h1>
             
                <span className="sliderText">   Sustainable & Eco-Friendly</span>
              </h1>
              <p>
                Coconut coir is a natural, renewable resource, promoting
                environmental sustainability.
              </p>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <img
              src="../images/cor-2.jpeg"
              alt=""
              srcset=""
              className="carouselImage carouselImage2"
            />
            <div className="text cor-design-34" >
  
              <h1>
         
                <span className="sliderText">   Redefining Sustainable Living</span>
        
              </h1>
              <p>
                Make the switch to eco-conscious choices. Our coir products
                support a greener, cleaner planet.
              </p>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <img
              src="../images/cor-3.jpeg"
              alt=""
              srcset=""
              className="carouselImage"
            />
            <div className="text cor-design-34" >
           
              <h1>
              <span className="sliderText">From Home to Industry</span>
              
              </h1>
              <p>
                From eco-friendly mats to superior horticultural substrates,
                coconut coir has a wide range of applications across industries.
              </p>
            </div>
          </Carousel.Item>
        </Carousel>
   

      </div>
      <div className="aboutus pt-2 pb-5">
<h1 className="abouth1">About Us</h1>
<div className="about mt-3">
    <div className="left">
    
<img src="../images/logo2.jpeg" alt="" srcset="" className="img1"/>

    </div>
    <div className="right">
<h4 className="few-words-about-us"> Few Words About Us</h4>
<p data-aos='fade-up' data-aos-duration='1500'>
At M2Balu Export, we harness the natural abundance of coconut coir to produce high-quality, eco-friendly products. With a commitment to sustainability and excellence, our journey began with a single manufacturing unit and has grown to encompass multiple facilities, providing versatile solutions for various agricultural and horticultural needs. Join us in promoting a greener future with innovative coir products
</p>

<button className='morebtn' data-aos='fade-left' data-aos-duration='1500'>
<Link to='/about'  onClick={() => window.location.href = '/about'}>Discover More</Link>
</button>

    </div>
</div>
      </div>

      <div className="products pt-5 pb-5" style={{width:"100%"}} >
        <h1>Explore Our Products</h1>
        <p>
          Explore the full range of our high-quality, eco-friendly coir
          solutions designed to meet your agricultural and horticultural needs.
        </p>
        <div className="carouselx">
          <div className="cards">
            <div className="imgsec">
              <img
                src="../images/images_-5-1-AR0JOoJZZkIlrbRG.jpeg"
                alt=""
                srcset=""
              />
            </div>
            <div className="heading">
            <h4>
  <a href="/product1">
    Coir Pith (Coco Peat)
  </a>
</h4>
            </div>
            {/* <div className="content"> 
                <Button variant="outlined">See More</Button>
            </div> */}
          </div>
          <div className="cards">
            <div className="imgsec">
              <img
               src="../images/coirpithblock/m2balu_20240527_131558_0000-1-AQEJabLE72CBBze1.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="heading">
                <h4><a href="/product2">Coir Pith Blocks (5Kg)</a></h4>
            </div>
            {/* <div className="content">
                <Button variant="outlined">See More</Button>
            </div> */}
          </div>
          <div className="cards">
            <div className="imgsec">
              <img
             src="../images/coirfiber/coir-fiber-4-m5KvaoERrJcZW3DB.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="heading">
                <h4><a href="/product3">Coir Fiber</a></h4>
            </div>
            {/* <div className="content">
             
                <Button variant="outlined">See More</Button>
            </div> */}
          </div>
          <div className="cards">
            <div className="imgsec">
              <img
                                src="../images/cocopeat/briquettes-2-A3QpbKa50lTg85JY.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="heading">
                <h4><a href="/product4">Coco Peat 650 Briquettes</a></h4>
            </div>
            {/* <div className="content">
                <Button variant="outlined">See More</Button>
            </div> */}
          </div>
          <div className="cards">
            <div className="imgsec">
              <img
                src="../images/coirhusk/husk-1-AR0JXJZG0ruDR9GD.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="heading">
                <h4><a href="/product5">Coir Husk Chips</a></h4>
            </div>
            {/* <div className="content">
                <Button variant="outlined">See More</Button>
            </div> */}
          </div>
          <div className="cards">
            <div className="imgsec">
              <img
               src="../images/coiryarn/coconut-coir-yarn-1000x1000-d95pjxkkljuXG6Da.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="heading">
                <h4><a href="/product6">Coir Yarn</a></h4>
            </div>
            {/* <div className="content">
                <Button variant="outlined">See More</Button>
            </div> */}
          </div>
          <div className="cards">
            <div className="imgsec">
              <img
              src="../images/cocopeatbag/grow-bag-2-A1aJVqwP6ycGazox.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="heading">
                <h4 className="coco-pt-gb"><a href="/product7" >Coco Peat Grow Bag</a></h4>
            </div>
            {/* <div className="content">
                <Button variant="outlined">See More</Button>
            </div> */}
          </div>
       
        </div>
        <div className="row w-100 mt-5 d-flex justify-content-center">
<div className="col-md-2">
<button className='morebtn'>
<Link to='/products' onClick={() => window.location.href = '/products'}>View All Products</Link>
</button>
</div>
        </div>
      </div>


     <div className="workprocess pt-5 pb-5
      ">
        <div className="row w-100" >
            <h1>How We Work</h1>
            <p>Take a closer look at our innovative and sustainable manufacturing process that ensures the highest quality coir products.</p>
        </div>
<div className="row w-100 mt-4 d-flex justify-content-around">
<div className="col-md-4 processCard">
  <h4 data-aos="fade-right"  data-aos-duration="1500">Step 1</h4>
{/* <div className="divsec" data-aos="fade-up" data-aos-duration="1500">
<div className="circle2">
<div className="circle"  data-aos="zoom-in" data-aos-duration="1500"   data-aos-delay="400">
Collection of Raw Materials
</div>
</div>
<div className="rectangle">
<div class="overlay"></div>
<p>
Our process begins with the collection of coconut husks from local farms, ensuring that we use fresh, high-quality raw materials while supporting local agriculture.
</p>
</div>
</div> */}

<Card sx={{ maxWidth: 345}} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/50-YNqJvno8oqH58l4z.avif"
        title="green iguana"
      />
      <CardContent sx={{ maxHeight:200}}>
        <Typography gutterBottom variant="h5" component="div">
      Collection of Raw Materials
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        Our process begins with the collection of coconut husks from local farms, ensuring that we use fresh, high-quality raw materials while supporting local agriculture.
        </Typography>
      </CardContent>
  
    </Card>


</div>
<div className="col-md-4 processCard" >
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 2</h4>
<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/17-YbNaoZj8Xvfn1nX1.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
     Fiber Extraction
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The husks are processed through defibering machines to extract the coir fibers. The fibers are separated from the pith and outer shell.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard" >
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 3</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/24-mxBrv8yp2gfN7XQE (1).avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
    
Cleaning and Drying
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The extracted fibers are then cleaned to remove any residual impurities and thoroughly dried. This step is crucial to ensure that the fibers maintain their strength and durability.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 4</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/08-mePLlGBGp7iby7j7.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Baling of Fiber
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The spun coir fibers are compacted into bales. This process involves compressing the fibers into tightly packed bales, which are then secured with ties. Baling helps in easy handling, storage, and transportation of the coir fibers.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 5</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/coir-yarn-6-A3QJyGkwwlSB2Qr6.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Spinning and Weaving
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The cleaned and dried fibers are spun into yarns or ropes. These yarns are then woven into various products such as mats, rugs, and other coir-based items.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 6</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/10-AzGrq7qG6gi7LRXL.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Collection of Coir Pith
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The pith, a byproduct of the fiber extraction process, is collected and cleaned to remove any impurities.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 7</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/09-m7VprKWo8Dfb04zx.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">

        Drying of coir pith
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The collected pith is dried to reduce moisture content, ensuring it is suitable for block formation.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 8</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/47-AR0J6yp81lUX9rRn.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Compression
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The dried pith is then compressed into blocks using hydraulic presses. This step ensures the blocks are compact and easy to handle.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 9</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/gallery-2-YX4b90GGbqszBQ5n.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
Product Formation
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        The spun and baled coir fibers, along with the pith blocks, are further processed to form different coir products. This includes creating grow bags, seedling cups, and husk chips. 
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 10</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/web-17-dJo40xEMKPUnpRwL.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Quality Control
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        Throughout the manufacturing process, stringent quality control measures are in place. Each product undergoes rigorous testing to ensure it meets our high standards for durability, performance, and eco-friendliness.
        </Typography>
      </CardContent>
  
    </Card>
</div>

<div className="col-md-4 processCard">
<h4 data-aos="fade-right"  data-aos-duration="1500">Step 11</h4>

<Card sx={{ maxWidth: 345 }} data-aos="fade-up"  data-aos-duration="1500">
      <CardMedia
        sx={{ height: 200 }}
        image="./images/work/pith-block-6-A854y7rMjEIPbjk1.avif"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
      
Packaging and Distribution
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.dark' }}>
        Once the products pass quality control, they are carefully packaged using eco-friendly materials. We then distribute our products to various markets, ensuring timely delivery and customer satisfaction.
        </Typography>
      </CardContent>
  
    </Card>
</div>

</div>

      </div>
      <Container>
      <div style={{ position: 'relative', textAlign: 'center', margin: '20px 0' }}>
            <img 
                src="../images/thumbnail.jpeg" 
                alt="Descriptive Alt Text" 
                style={{ width: '100%', height: 'auto', borderRadius: '8px' }} 
            />
            <Button 
                onClick={handleShow} 
                style={{
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'darkgreen', 
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                }}
                className="watch-video-btn"
            >
                Watch Video
            </Button>
            <Modal show={showModal} onHide={handleClose} centered dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <video
                        style={{ width: "100%", height: "auto", borderRadius: "8px" }} 
                        controls
                    >
                        <source src="../Images/WhatsApp Video 2024-10-04 at 11.31.56 AM (1).mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Modal.Body>
            </Modal>
        </div>
        </Container>
        
{/* <div className="whychoose pt-5 pb-5">

  <div className="rght text-start" >
<h1 data-aos='flip-up' data-aos-duration='1500'>Reason for Choosen Us</h1>
<p>Experience the best service with us. Trust our expertise and dedication to excellence. </p>
 <div className="row w-100">
 <div className="col-md-3">
  <div className=" d-flex justify-content-center">
      <div className="offercard mt-5" data-aos='zoom-in-up' data-aos-duration='1500'>
     <div className="top">
     <p>
     Our products are made from natural coconut fibers, ensuring minimal environmental impact.
        </p>
     </div>
        <hr />
        <h4 style={{fontSize:"15px"}}>
      Sustainability
        </h4>
      </div>
    </div>
 </div>
  <div className="col-md-3">
  <div className=" d-flex justify-content-center">
      <div className="offercard mt-5" data-aos='zoom-in-up' data-aos-duration='1500'>
     <div className="top">
     <p>
We adhere to the highest quality standards, ensuring our products are reliable and long-lasting.
        </p>
     </div>
        <hr />
        <h4 style={{fontSize:"15px"}}>
     Quality
        </h4>
      </div>
    </div>
 </div>
  <div className="col-md-3">
  <div className=" d-flex justify-content-center">
      <div className="offercard mt-5" data-aos='zoom-in-up' data-aos-duration='1500'>
     <div className="top">
     <p>
    We continuously strive to innovate and improve our product offerings to meet the evolving needs of our customers.
        </p>
     </div>
        <hr />
        <h4 style={{fontSize:"15px"}}>
     Innovation
        </h4>
      </div>
    </div>
 </div>
  <div className="col-md-3">
  <div className=" d-flex justify-content-center">
      <div className="offercard mt-5" data-aos='zoom-in-up' data-aos-duration='1500'>
     <div className="top">
     <p>
    Our customers are at the heart of everything we do. We are dedicated to providing exceptional service and support.
        </p>
     </div>
        <hr />
        <h4 style={{fontSize:"15px"}}>
Customer-Centric
        </h4>
      </div>
    </div>
 </div>
 </div>

  </div>
</div> */}
<WhyChoosenUsCard/>

<Testimonial/>

    </div>
  );
}

export default Home;
