import React,{useEffect} from "react";
import "../css/product1.css";
import OwlCarousel from "react-owl-carousel";
import Contact from "./Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function Product4() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
  
  <section
            id="about"
            style={{
                height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                   width:"100%"
                }}
              >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)",padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
<div className="mainp" style={{marginTop: "10px"}}>

<div className="product-main">
        <div className="lftside">
          <OwlCarousel loop margin={5} nav {...options} className="owlslide">
            <div class=" verticleCard">
              <img
                src="../images/cocopeat/briquettes-2-A3QpbKa50lTg85JY.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                  src="../images/cocopeat/briquettes-3-YX4PowX1RBc5nb93.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
      src="../images/cocopeat/briquettes-5-AQEJwv4vRyCQPo2E.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                src="../images/cocopeat/e07714fa0d168707d41e4e91936a89bd-AR0JX4ZkRZtJ2NxL.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
         
          </OwlCarousel>
        </div>
        <div className="rghtside" data-aos="fade-down">
            <h1>
            What are Coco Peat 650 Briquettes and Why Use Them?
            </h1>
            <p>
            Coco peat 650 briquettes are compressed blocks of coconut coir fibers that are widely used as a growing medium and soil amendment in gardening and horticulture. Here's why you should consider using coco peat 650 briquettes: </p>
        </div>

        </div>

 <div className="container"  style={{color:"#fff"}}>
    <div className="row w-100 ">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down" >
<h4>
Superior Water Retention and Aeration
</h4>
<ul className="text-start">
    <li>
    Consistent Moisture Levels: Coco peat has excellent water retention properties, ensuring that plants receive consistent moisture levels, promoting healthy growth.    </li>
    <li>
    Improved Aeration: Its fibrous structure promotes air circulation within the soil, preventing waterlogging and allowing for better root development. </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down" >
<h4>
Nutrient-Rich Growing Medium


</h4>
<ul className="text-start">
    <li>
    Natural Fertility: Coco peat contains essential nutrients such as potassium, phosphorus, and micronutrients, providing a conducive environment for plant growth.
    </li>
    <li>
    pH Neutral: It has a near-neutral pH level, making it suitable for a wide range of plants and allowing for better nutrient uptake.
    </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border myCol  p-3" data-aos="fade-down">
<h4>
Sustainable and Eco-Friendly
</h4>
<ul className="text-start">
    <li>
    Renewable Resource: Coco peat is derived from coconut husks, a byproduct of the coconut industry, making it a sustainable alternative to peat moss.
</li>
<li>
Biodegradable: It decomposes naturally over time, enriching the soil with organic matter and reducing environmental impact.
</li>

</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down">
<h4>
Convenient and Easy to Use
</h4>
<ul>
  <li>
  Compact Packaging: Coco peat 650 briquettes are lightweight and compact, making them easy to transport and store.
  </li>
  <li>
  Hydrates Quickly: Simply add water to the briquette, and it will expand to several times its original size, ready to use as a growing medium or soil amendment.
  </li>
</ul>

</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border p-3 myCol"  data-aos="fade-down">
<h4>
Versatile Applications
</h4>
<ul className="text-start">
    <li>
    Container Gardening: Ideal for use in pots, containers, and raised beds, providing a lightweight and nutrient-rich medium.
  </li>
  <li>
  Seed Starting: Suitable for starting seeds and propagating cuttings, offering a sterile and weed-free environment for young plants.
  </li>
  <li>
  Soil Amendment: Improves soil structure, enhances moisture retention, and promotes microbial activity in garden beds and landscapes.
  </li>
   
</ul>
</div>
    </div>
 </div>
</div>
<Contact/>

    </div>
  );
}

export default Product4;
