import React,{useEffect} from "react";
import "../css/product1.css";
import OwlCarousel from "react-owl-carousel";
import Contact from "./Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function Product7() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
  
  <section
            id="about"
            style={{
                 height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                   width:"100%"
                }}
              >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div>
                  <Breadcrumb style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
<div className="mainp" style={{marginTop: "10px"}}>

<div className="product-main">
        <div className="lftside">
          <OwlCarousel loop margin={5} nav {...options} className="owlslide">
            <div class=" verticleCard">
              <img
                src="../images/cocopeatbag/grow-bag-2-A1aJVqwP6ycGazox.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
       src="../images/cocopeatbag/grow-bag-7-m2WQON8jy3Tqn0Ok.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
               src="../images/cocopeatbag/grow-bag-10-AR0bp8M9nwcWKr9Y.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            
          </OwlCarousel>
        </div>
        <div className="rghtside" data-aos="fade-down">
            <h1>
            What is a Coco Grow Bag and Why Use It?
            </h1>
            <p>
            Coco grow bags, made from compressed coconut husk fibers (coco peat), provide an ideal growing medium for a variety of plants. Here's why you should choose coco grow bags:</p>
        </div>

        </div>

 <div className="container"  style={{color:"#fff"}}>
    <div className="row w-100 ">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down">
<h4>
Superior Water Retention and Aeration
</h4>
<ul className="text-start">
    <li>
    Optimal Hydration: Coco peat retains water effectively, ensuring that plants receive consistent moisture.  </li>
    <li>
    Excellent Drainage: Prevents waterlogging, reducing the risk of root rot. </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down" >
<h4>
Nutrient-Rich Medium
</h4>
<ul className="text-start">
    <li>
    Natural Fertility: Rich in essential nutrients, coco peat supports healthy plant growth.    </li>
    <li>
    Enhanced Root Development: Promotes strong and healthy root systems.    </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border myCol  p-3" data-aos="fade-down"  >
<h4>
Eco-Friendly and Sustainable
</h4>
<ul className="text-start">
    <li>
    Renewable Resource: Made from the husks of coconuts, a plentiful and renewable resource.
</li>
<li>
Biodegradable: Naturally decomposes without harming the environment.
</li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down">
<h4>
Versatile and Convenient
</h4>
<ul>
  <li>
  Wide Applications: Suitable for a variety of plants, including vegetables, flowers, and herbs.  </li>
<li>
Easy to Use: Pre-filled and ready to use, coco grow bags simplify the planting process.
</li>
</ul>

</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down">
<h4>
Pest and Disease Resistant
</h4>
<ul className="text-start">
    <li>
    Natural Resistance: Coco peat is naturally resistant to pests and diseases, reducing the need for chemical treatments.
  </li>   
</ul>
</div>
    </div>
 </div>
</div>
<Contact/>

    </div>
  );
}

export default Product7;
