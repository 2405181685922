import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import "../css/navbar.css";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar expand="lg" className={`navbar ${scrolled ? "scrolled" : ""}`}>
        <Container fluid className="back-color-gradient">
          <Navbar.Brand href="#">
            <img
              src="../images/logo.png"
              alt="Logo"
              className={`logoimg ${scrolled ? "logo-hidden" : ""}`}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="navbar-toggle"
          />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            className="mt-5 offcanvas-custom"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id="offcanvasNavbarLabel"
                className="music-logo-1"
              >
                M2 Balu Exports
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 navlist">
                <Nav.Link
                  as="span"
                  onClick={() => (window.location.href = "/")}
                  className="nav-item"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as="span"
                  onClick={() => (window.location.href = "/about")}
                  className="nav-item"
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  as="span"
                  onClick={() => (window.location.href = "/products")}
                  className="nav-item"
                >
                  Our Product
                </Nav.Link>
                <Nav.Link
                  as="span"
                  onClick={() => (window.location.href = "/gallery")}
                  className="nav-item"
                >
                  Gallery
                </Nav.Link>
                <Nav.Link
                  as="span"
                  onClick={() => (window.location.href = "/contact-us")}
                  className="nav-item"
                >
                  Contact Us
                </Nav.Link>
              </Nav>
              <div className="callbtn">
                <a href="/contact-us" className="call-button">
                  <IoMdCall className="call" />
                  &nbsp; Call Anytime
                </a>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
