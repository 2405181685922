import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Product from './components/Product';
import Product1 from './components/Product1';
import Product2 from './components/Product2';
import Product3 from './components/Product3';
import Product4 from './components/Product4';
import Product5 from './components/Product5';
import Product6 from './components/Product6';
import Product7 from './components/Product7';
import Product8 from './components/Product8';
import Product9 from './components/Product9';
import Contactus from './components/Contactus';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import ScrollBtn from './components/ScrollBtn';
import WhatsAppButton from './components/WhatsAppButton';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
  <BrowserRouter>
<Header/>
  <Routes>
    <Route path="/" element={
      <><Home/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/about' element={
      <>
      <About/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/products' element={
      <><Product/>
      <WhatsAppButton/>
      </>}/>
      
    <Route path='/product1' element={<><Product1/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product2' element={<><Product2/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product3' element={<><Product3/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product4' element={<><Product4/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product5' element={<><Product5/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product6' element={<><Product6/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product7' element={<><Product7/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product8' element={<><Product8/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/product9' element={<><Product9/>
      <WhatsAppButton/>
      </>}/>
    <Route path='/contact-us' element={<><Contactus/>  <WhatsAppButton/></>}/>
    <Route path='/gallery' element={<><Gallery/>
      <WhatsAppButton/></>}/>
  </Routes>
  <ScrollBtn/>
  <Footer/>
  </BrowserRouter>
    </div>
  );
}

export default App;
