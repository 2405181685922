import React,{useEffect} from "react";
import "../css/product1.css";
import OwlCarousel from "react-owl-carousel";
import Contact from "./Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function Product8() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
  <section
            id="about"
            style={{
                height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                   width:"100%"
                }}
              >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>

<div className="mainp" style={{marginTop: "10px"}}>

<div className="product-main">
        <div className="lftside">
          <OwlCarousel loop margin={5} nav {...options} className="owlslide">
            <div class=" verticleCard">
              <img
                src="../images/seedlingcups/seedling-cup-4-AGB49jZoO7tRK5Xv.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
              src="../images/seedlingcups/seedling-cup-5-YD04952WlOcLyLKQ.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                  src="../images/seedlingcups/seedling-cup-8-YNqrELxKVguJo55n.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
               src="../images/seedlingcups/seedling-cup-9-Yleyj0xJBocQRLb5.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                  src="../images/seedlingcups/seedling-cup-13-YD049526vgFWkB2Y.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
          </OwlCarousel>
        </div>
        <div className="rghtside" data-aos="fade-down">
            <h1>
            What is a Coir Seedling Cup and Why Use It?
            </h1>
            <p>
            Coir seedling cups are biodegradable planting containers made from natural coconut coir fibers. These cups provide an eco-friendly and efficient solution for starting and nurturing seedlings. Here’s why you should consider using coir seedling cups:</p>
        </div>

        </div>

 <div className="container"  style={{color:"#fff"}}>
    <div className="row w-100 ">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down">
<h4>
Eco-Friendly and Sustainable
</h4>
<ul className="text-start">
    <li>
    Renewable Resource: Made from coconut husks, a byproduct of the coconut industry, coir seedling cups are a sustainable alternative to plastic and peat-based pots. </li>
    <li>
    Biodegradable: Coir cups decompose naturally, reducing waste and environmental impact. They can be planted directly into the soil, where they will break down and enrich the soil. </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down" >
<h4>
Promotes Healthy Root Development
</h4>
<ul className="text-start">
    <li>
    Root Aeration: The fibrous structure of coir allows for excellent air circulation around the roots, promoting healthier and more robust root systems.   </li>
    <li>
    Moisture Retention: Coir seedling cups retain moisture effectively, ensuring that seedlings receive consistent hydration without waterlogging.  </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border myCol  p-3"  data-aos="fade-down">
<h4>
Easy Transplanting
</h4>
<ul className="text-start">
    <li>
    Reduce Transplant Shock: Because the entire cup can be planted directly into the soil, there is minimal root disturbance, reducing transplant shock and promoting better establishment in the garden.
</li>
<li>
Convenience: Simplifies the transplanting process, making it easier for gardeners to manage their seedlings.
</li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down" >
<h4>
Pest and Disease Resistance
</h4>
<ul>
  <li>
  Natural Resistance: Coir is naturally resistant to pests and diseases, providing a healthier environment for young plants without the need for chemical treatments.</li>

</ul>

</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down">
<h4>
Versatile Applications
</h4>
<ul className="text-start">
    <li>
    Wide Range of Plants: Suitable for starting a variety of plants, including vegetables, herbs, flowers, and ornamental plants.
  </li>  
  <li>
  Indoor and Outdoor Use: Ideal for use in both indoor and outdoor gardening setups, including greenhouses and nurseries.
    </li> 
</ul>
</div>
    </div>
 </div>
</div>
<Contact/>

    </div>
  );
}

export default Product8;
