import React,{useEffect} from 'react'
import TextField from '@mui/material/TextField';
import '../css/contactus.css'
import { MdOutlineLocalPhone } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { GoClockFill } from "react-icons/go";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css";
import Card from 'react-bootstrap/Card';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { Container } from 'react-bootstrap';
import { FaLinkedinIn } from "react-icons/fa6";

function Contactus() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
       <section
            id="about"
            style={{
                 height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/contactus-bck.PNG)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                  width:"100%"
                }}
              >
                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                Contact Us
                  </h1>
                </div>

                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                   Contact Us
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
          <Container>
          <div className="row mt-4 mb-4" style={{width:"100%"}}>
  <div className="container  gap-3">
  <div className="col-md-4">
  <div class="card" style={{
  background: "linear-gradient(to top, #e1e276 0%, #f8f8f1 100%)",
  color: "black"
}} >
  <div class="card-body" style={{height:"100px"}}>
  <div>
          <div className="d-flex gap-3">
            <div style={{fontSize:"35px"}} className="clock-icon"><GoClockFill/></div>
            <div>
              <div className="align-items-left">
                <span>Monday - Saturday</span>
                <span style={{display:"block",textAlign:"left"}}>9am - 5pm</span>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
  </div>
  <div className="col-md-4">
  <div class="card"  style={{
  background: "linear-gradient(to top, #e1e276 0%, #f8f8f1 100%)",
  color: "black"
}}>
  <div class="card-body" style={{height:"100px"}}>
  <div>
          <div className="d-flex gap-3">
            <div style={{fontSize:"30px"}}  className="phone-vibrate"> <FaPhoneAlt/> </div>
            <div>
              <div className="align-items-left">
                <span>+919976999900</span>
                <span style={{display:"block",textAlign:"left"}}>+919659599900</span>
                <span style={{display:"block",textAlign:"left"}}>+919092429900</span>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
  </div>
  <div className="col-md-4">
  <div class="card"  style={{
  background: "linear-gradient(to top, #e1e276 0%, #f8f8f1 100%)",
  color: "black"
}}>
  <div class="card-body" style={{height:"100px"}}>
  <div>
          <div className="d-flex gap-3">
            <div style={{fontSize:"35px"}} className="email-bounce"> <MdEmail/> </div>
            <div>
              <div className="align-items-left mt-3">
                <span>info@m2baluexport.com</span>
                {/* <span style={{display:"block",textAlign:"left"}}>info@m2baluexport.com</span> */}
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
  </div>
</div>
</div>
</Container>
<div class="address-section">
    <h2 class="address-title">Our Addresses</h2>
    <div class="address-container">
        <div class="address-card office">
            <div class="icon-container">
                <img src="../images/loc-pic.png" alt="Office Icon" class="address-icon" />
            </div>
            <h3 class="address-heading">Office Address</h3>
            <p class="address-text">
                110 G/2 Jayaprakash Street,<br />
                Kottur Malayandipattinam,<br />
                Pollachi, Coimbatore,<br />
                Tamil Nadu 642114
            </p>
        </div>

        <div class="address-card factory">
            <div class="icon-container">
                <img src="../images/loc-pic.png" alt="Factory Icon" class="address-icon" />
            </div>
            <h3 class="address-heading">Factory Address</h3>
            <p class="address-text">
                SF, 775 A1, Indra Nagar Road,<br />
                Near Bahavathi Amman Temple,<br />
                Kottur, Pollachi,<br />
                Tamil Nadu 642114
            </p>
        </div>
    </div>
</div>



<div className="contactmain">
      <div className="contactlft">
        <h1>Get in touch</h1>
        <img
          src="../images/flat-design-illustration-customer-support.png"
          alt="Customer Support"
          className='contactIllustration'
        />
        <h6>Connect with us:</h6>
        <div className="lftfooters">
          <a href="https://www.facebook.com/people/M2Balu-Export/61561456586077/" target="_blank" rel="noopener noreferrer">
            <FaFacebookF className='socials' />
          </a>
          <a href="https://x.com/i/flow/login?redirect_after_login=%2Fm2balu_export" target="_blank" rel="noopener noreferrer">
            <FaXTwitter className='socials' />
          </a>
          <a href="https://www.instagram.com/m2balu_export/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className='socials' />
          </a>
          <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn className='socials' />
          </a>
        </div>
      </div>
      <div className="contactrght">
        <h4 className='text-light'>Send us a message</h4>
        <p className='text-light'>Fill out the form below to reach out to us, and our team will get back to you as soon as possible.</p>
        <div className="group">
          <label htmlFor="name">Name*</label>
          <input type="text" id="name" />
        </div>
        <div className="group">
          <label htmlFor="lastName">Last Name*</label>
          <input type="text" id="lastName" />
        </div>
        <div className="group">
          <label htmlFor="email">Your Email*</label>
          <input type="email" id="email" />
        </div>
        <div className="group">
          <label htmlFor="message">Message*</label>
          <textarea id="message"></textarea>
        </div>
        <button className='morebtn mt-3'>
          <Link to='/about'>SUBMIT</Link>
        </button>
      </div>
    </div>







</div>
   
  )
}

export default Contactus