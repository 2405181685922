import React from "react";
import "../css/testimonial.css";
import Avatar from '@mui/material/Avatar';
import OwlCarousel from "react-owl-carousel";

function Testimonial() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    
    <div className="mainDiv">
      <div class="container-fluid customContainer text-center ">
        <div className="row w-100">
<h1 className="head">What Our Client Says</h1>
        </div>
        <div class="row customeRow align-items-center justify-content-center w-100">
        <OwlCarousel loop margin={5} nav {...options}>
              <div class="card customeCard border">
              <p>"Switching to M2BALU EXPORTS was one of the best decisions for our business. Their coir seedling cups are not only eco-friendly but also incredibly durable. Our customers love them, and we appreciate the commitment to sustainability."</p>
                <div className="iconImg">
                <div className="d-flex justify-content-center align-items-center">
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> &nbsp; &nbsp; &nbsp; — Subhashini Arya., Retail Store Owner
                </div>
                </div>
              </div>
              <div class="card customeCard border">
               
               
               <p>"We have partnered with M2BALU EXPORT for our landscaping projects, and they have never disappointed. Their coir ropes and twines are strong, flexible, and perfect for various applications. It's great to work with a company that values quality and sustainability as much as we do."</p>
                 <div className="iconImg">
                 <div className="d-flex justify-content-center align-items-center">
                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> &nbsp; &nbsp; &nbsp; — Arya Ramesh., Landscaping Company Owner
                 </div>
                 </div>
            
               </div>

               <div class="card customeCard border">
               
               
               <p>"The innovation at M2BALU EXPORT is truly impressive. Their products have helped us enhance our agricultural practices significantly. The coir grow bags are particularly effective, promoting healthy plant growth while being environmentally friendly."</p>
                 <div className="iconImg">
                 <div className="d-flex justify-content-center align-items-center">
                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> &nbsp; &nbsp; &nbsp; — Ashvin., Organic Farmer
                 </div>
                 </div>
            
               </div>

               <div class="card customeCard border">
               
               
               <p>“The coir pith blocks from M2BALU EXPORT are amazing! They expand significantly when hydrated and have greatly improved the quality of my soil. I use the coir pith blocks for my vegetable garden, and they work wonders. They retain moisture exceptionally well and have helped my plants grow healthier and stronger. The blocks are easy to use and expand quickly with water. Highly recommended for any gardener!”</p>
                 <div className="iconImg">
                 <div className="d-flex justify-content-center align-items-center">
                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> &nbsp; &nbsp; &nbsp; — Shibbu., Organic Farmer
                 </div>
                 </div>
            
               </div>
      
               <div class="card customeCard border">
               
               
               <p>“The coir fiber from M2BALU EXPORT is fantastic! I use it to make mats and ropes, and it holds up really well. The natural strength and resilience of the fibers are impressive, and they are easy to work with. Highly recommended!”</p>
                 <div className="iconImg">
                 <div className="d-flex justify-content-center align-items-center" >
                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"  /> &nbsp; &nbsp; &nbsp; — Ramesh Chandran., Coir rope manufacturer
                 </div>
                 </div>
            
               </div>
            </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
