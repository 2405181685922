import React,{useEffect} from 'react'
import '../css/product.css'
import { Link } from 'react-router-dom'
import Contact from './Contact'
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function Product() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
       <section
            id="about"
            style={{
                height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                  width:"100%"
                }}
              >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        <div className="row w-100 pt-5 pb-5 product gy-5">
            <div className="row w-100 mt-4" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
                <h1>Explore our products</h1>
                <p>
                Welcome to M2BALU EXPORT! Discover our premium range of eco-friendly coir products, designed to enhance your gardening, agricultural, and domestic needs. Our products are crafted from natural coconut fibers, offering sustainable and high-quality solutions for various applications
                </p>
            </div>
            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss" >
            <div className="imgsecs">
              <img
                src="../images/images_-5-1-AR0JOoJZZkIlrbRG.jpeg"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coir Pith (Coco Peat)</h4>
                <Link to='/product1'  onClick={() => window.location.href = '/product1'}>See More</Link>
            </div>
          </div>
            </div>


            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss" >
            <div className="imgsecs">
              <img
  src="../images/coirpithblock/m2balu_20240527_131558_0000-1-AQEJabLE72CBBze1.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coir Pith Block (5Kg)</h4>
                <Link to='/product2' onClick={() => window.location.href = '/product2'}>See More</Link>
            </div>
          </div>
            </div>

            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss">
            <div className="imgsecs">
              <img
             src="../images/coirfiber/coir-fiber-4-m5KvaoERrJcZW3DB.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coir Fiber</h4>
                <Link to='/product3' onClick={() => window.location.href = '/product3'}>See More</Link>
            </div>
          </div>
            </div>

            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss" >
            <div className="imgsecs">
              <img
                 src="../images/cocopeat/briquettes-2-A3QpbKa50lTg85JY.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coco Peat 650 Briquettes
                </h4>
                <Link to='/product4' onClick={() => window.location.href = '/product4'}>See More</Link>
            </div>
          </div>
            </div>


            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss" >
            <div className="imgsecs">
              <img
                    src="../images/coirhusk/husk-1-AR0JXJZG0ruDR9GD.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coir Husk Chips</h4>
                <Link to='/product5' onClick={() => window.location.href = '/product5'}>See More</Link>
            </div>
          </div>
            </div>


            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss">
            <div className="imgsecs">
              <img
               src="../images/coiryarn/coconut-coir-yarn-1000x1000-d95pjxkkljuXG6Da.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coir Yarn</h4>
                <Link to='/product6' onClick={() => window.location.href = '/product6'}>See More</Link>
            </div>
          </div>
            </div>

            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss" >
            <div className="imgsecs">
              <img
                src="../images/cocopeatbag/grow-bag-2-A1aJVqwP6ycGazox.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coco Peat Grow Bag</h4>
                <Link to='/product7' onClick={() => window.location.href = '/product7'}>See More</Link>
            </div>
          </div>
            </div>

            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss" >
            <div className="imgsecs">
              <img
           src="../images/seedlingcups/seedling-cup-4-AGB49jZoO7tRK5Xv.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coir Seedling Cups</h4>
                <Link to='/product8' onClick={() => window.location.href = '/product8'}>See More</Link>
            </div>
          </div>
            </div>

            <div className="col-md-4  d-flex justify-content-center" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="cardss" >
            <div className="imgsecs">
              <img
                    src="../images/coirrope/coir-rope-2-AMq4GwMD7Xu2RWR5.avif"
                alt=""
                srcset=""
              />
            </div>
            <div className="headings">
                <h4>Coir Rope</h4>
                <Link to='/product9' onClick={() => window.location.href = '/product9'}>See More</Link>
            </div>
          </div>
            </div>
        
        </div>
        <Contact/>
    </div>
  )
}

export default Product