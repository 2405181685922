import React,{useEffect} from "react";
import "../css/product1.css";
import OwlCarousel from "react-owl-carousel";
import Contact from "./Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function Product2() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
<section
            id="about"
            style={{
                height: "40vh",
                width: "100%",
                // zIndex: 2,
                backgroundImage: "url(/images/back-abt.jpeg)", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // position: "relative",
            }}
          >
            <div className="container">
              <div
                className="breadcrumbt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "160px",
                  boxSizing: "border-box",
                   width:"100%"
                }}
              >
                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)",padding: "10px", borderRadius: "8px", textAlign: "center", marginBottom: "15px" }}>
                  <h1 className="abtus-heading" style={{ color: "white" }}>
                   Products
                  </h1>
                </div>

                <div>
                  <Breadcrumb style={{ backgroundColor: "rgba(0, 0, 0, 0.5)",padding: "10px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "white",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/" style={{ color: "white" }}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ color: "white" }}>
                     Products
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
<div className="mainp" style={{marginTop: "10px"}}>

<div className="product-main">
        <div className="lftside">
          <OwlCarousel loop margin={5} nav {...options} className="owlslide">
            <div class=" verticleCard">
              <img
                src="../images/coirpithblock/m2balu_20240527_131558_0000-1-AQEJabLE72CBBze1.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
                src="../images/coirpithblock/pith-block-5-Awvk0GNJ2ofzRPQaavif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
            <div class=" verticleCard">
              <img
        src="../images/coirpithblock/th-12-A3Qp1eR3wNUJwpJr.avif"
                alt=""
                srcset=""
                data-aos="fade-down"
              />
            </div>
     
          
          </OwlCarousel>
        </div>
        <div className="rghtside" data-aos="fade-down">
            <h1>
            What is Coco Peat 5kg Block and Why Use It?
            </h1>
            <p>
            A coco peat 5kg block is a compressed brick of coconut coir fibers that expands when hydrated. It serves as an excellent alternative to traditional soil mediums for gardening and horticultural purposes. Here's why you should consider using coco peat 5kg blocks:            </p>
    
        </div>

        </div>

 <div className="container"  style={{color:"#fff"}}>  
    <div className="row w-100 ">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down" >
<h4>
Superior Water Retention and Aeration
</h4>
<ul className="text-start">
    <li>
    Optimal Moisture Control: Coco peat has exceptional water retention capabilities, ensuring that plants receive consistent moisture levels.    </li>
    <li>
    Improved Aeration: Its fibrous structure promotes air circulation within the soil, preventing waterlogging and enhancing root development.    </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3" data-aos="fade-down">
<div className="col-md-9 border offset-3 p-3 myCol" >
<h4>
Nutrient-Rich Growing Medium

</h4>
<ul className="text-start" data-aos="fade-down">
    <li>
    Natural Fertility: Coco peat contains essential nutrients such as potassium, phosphorus, and micronutrients, providing a conducive environment for plant growth.
    </li>
    <li>
    pH Neutral: It has a near-neutral pH level, making it suitable for a wide range of plants and allowing for better nutrient uptake.
    </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3" data-aos="fade-down">
<div className="col-md-9 border myCol  p-3" data-aos="fade-down" >
<h4>
Sustainable and Eco-Friendly
</h4>
<ul className="text-start">
    <li>
    Renewable Resource: Coco peat is derived from coconut husks, a byproduct of the coconut industry, making it a sustainable alternative to peat moss.   </li>
   
    <li>
    Biodegradable: It decomposes naturally over time, enriching the soil with organic matter and reducing environmental impact.
    </li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border offset-3 p-3 myCol" data-aos="fade-down" >
<h4>
Convenient and Easy to Use
</h4>
<ul className="text-start">
    <li>
    Compact Packaging: Coco peat 5kg blocks are lightweight and compact, making them easy to transport and store.   
</li>
<li>
Hydrates Quickly: Simply add water to the block, and it will expand to several times its original size, ready to use as a growing medium or soil amendment.
</li>
</ul>
</div>
    </div>

    <div className="row w-100  mt-3">
<div className="col-md-9 border p-3 myCol" data-aos="fade-down">
<h4>
Versatile Applications
</h4>
<ul className="text-start">
    <li>
    Container Gardening: Ideal for use in pots, containers, and raised beds, providing a lightweight and nutrient-rich medium.
  </li>
   <li>
   Seed Starting: Suitable for starting seeds and propagating cuttings, offering a sterile and weed-free environment for young plants.
   </li>
   <li>
   Soil Amendment: Improves soil structure, enhances moisture retention, and promotes microbial activity in garden beds and landscapes.
   </li>
</ul>
</div>
    </div>
 </div>
</div>
<Contact/>

    </div>
  );
}

export default Product2;
